import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ServiceImg from "../assets/Background1.png";
import Footer from "../components/Footer";
import LedgerPageContent from "../pages/LedgerPageContent";
function Ledger() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={ServiceImg}
        title="Ledger"
        text="Keep track of your trades"
        url="/watchlist"
        btnClass="hide"
        buttonText="Watchlist"
      />
      <LedgerPageContent />

      <Footer />
    </>
  );
}
export default Ledger;
