import React, { useState } from "react";
import logo from "../assets/Logo_noBackground.png";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import { useStytchSession } from "@stytch/stytch-react";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const session = useStytchSession();

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <nav className="NavbarItems">
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="logo-img" className="logo-img" />
        finvision
      </Link>
      <div className="menu-icons" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <Link className={item.cName} to={item.url}>
              <i className={item.icon}></i>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
