import "./TripStyles.css";
import TripData from "./TripData";
import bg1 from "../assets/Background1.png";
import bg2 from "../assets/Background2.png";
import bg3 from "../assets/Background3.png";

function Trip() {
  return (
    <div className="trip">
      <h1>Recent Videos</h1>
      <p></p>
      <div className="tripcard">
        <TripData image={bg1} heading="background 1" text="1/3 choices" />
        <TripData image={bg2} heading="background 2" text="2/3 choices" />
        <TripData image={bg3} heading="background 3" text="3/3 choices" />
      </div>
    </div>
  );
}

export default Trip;
