const tickerSymbols = [
  "A",
  "AA",
  "AAL",
  "AAON",
  "AAP",
  "AAPL",
  "ABBV",
  "ABC",
  "ABCB",
  "ABCL",
  "ABCM",
  "ABM",
  "ABNB",
  "ABR",
  "ABT",
  "ACAD",
  "ACGL",
  "ACHC",
  "ACI",
  "ACIW",
  "ACLS",
  "ACLX",
  "ACM",
  "ACN",
  "ACVA",
  "ADBE",
  "ADC",
  "ADI",
  "ADM",
  "ADNT",
  "ADP",
  "ADSK",
  "ADT",
  "AEE",
  "AEL",
  "AEM",
  "AEO",
  "AEP",
  "AER",
  "AES",
  "AFG",
  "AFL",
  "AFRM",
  "AGCO",
  "AGI",
  "AGL",
  "AGNC",
  "AGR",
  "AI",
  "AIG",
  "AIRC",
  "AIZ",
  "AJG",
  "AJRD",
  "AKAM",
  "AKRO",
  "AL",
  "ALB",
  "ALC",
  "ALGM",
  "ALGN",
  "ALIT",
  "ALK",
  "ALKS",
  "ALL",
  "ALLE",
  "ALLY",
  "ALNY",
  "ALSN",
  "ALTR",
  "ALV",
  "AM",
  "AMAT",
  "AMBA",
  "AMCR",
  "AMD",
  "AME",
  "AMED",
  "AMGN",
  "AMH",
  "AMKR",
  "AMN",
  "AMP",
  "AMPH",
  "AMRC",
  "AMT",
  "AMX",
  "AMZN",
  "AN",
  "ANET",
  "ANSS",
  "AON",
  "AOS",
  "APA",
  "APAM",
  "APD",
  "APG",
  "APH",
  "APLE",
  "APLS",
  "APO",
  "APP",
  "APPN",
  "APTV",
  "AQN",
  "AR",
  "ARCC",
  "ARE",
  "ARES",
  "ARLP",
  "ARMK",
  "ARNC",
  "ARRY",
  "ARW",
  "ARWR",
  "ASAI",
  "ASAN",
  "ASB",
  "ASGN",
  "ASH",
  "ASML",
  "ASND",
  "ASO",
  "ASX",
  "ATHM",
  "ATI",
  "ATKR",
  "ATO",
  "ATRC",
  "ATVI",
  "AU",
  "AVA",
  "AVB",
  "AVDX",
  "AVGO",
  "AVNT",
  "AVT",
  "AVTR",
  "AVY",
  "AWI",
  "AWK",
  "AX",
  "AXNX",
  "AXON",
  "AXP",
  "AXS",
  "AXSM",
  "AXTA",
  "AY",
  "AYI",
  "AYX",
  "AZEK",
  "AZN",
  "AZTA",
  "BA",
  "BABA",
  "BAC",
  "BAH",
  "BAK",
  "BALL",
  "BAM",
  "BAP",
  "BAX",
  "BB",
  "BBAR",
  "BBIO",
  "BBVA",
  "BBWI",
  "BBY",
  "BC",
  "BCC",
  "BCE",
  "BCS",
  "BDX",
  "BE",
  "BEAM",
  "BECN",
  "BEKE",
  "BEN",
  "BEPC",
  "BERY",
  "BFAM",
  "BF-B",
  "BG",
  "BHC",
  "BHF",
  "BHP",
  "BIDU",
  "BIIB",
  "BILI",
  "BILL",
  "BIP",
  "BIPC",
  "BJ",
  "BK",
  "BKH",
  "BKI",
  "BKNG",
  "BKR",
  "BL",
  "BLCO",
  "BLDR",
  "BLK",
  "BLMN",
  "BMBL",
  "BMO",
  "BMRN",
  "BMY",
  "BN",
  "BNL",
  "BNS",
  "BNTX",
  "BOOT",
  "BOWL",
  "BOX",
  "BP",
  "BPMC",
  "BPOP",
  "BR",
  "BRBR",
  "BRK-B",
  "BRKR",
  "BRO",
  "BROS",
  "BRP",
  "BRX",
  "BRZE",
  "BSAC",
  "BSBR",
  "BSM",
  "BSX",
  "BSY",
  "BTI",
  "BTU",
  "BUD",
  "BUR",
  "BURL",
  "BWA",
  "BWXT",
  "BX",
  "BXMT",
  "BXP",
  "BXSL",
  "BYD",
  "BZ",
  "C",
  "CADE",
  "CAG",
  "CAH",
  "CALM",
  "CALX",
  "CAR",
  "CARG",
  "CARR",
  "CAT",
  "CATY",
  "CB",
  "CBOE",
  "CBRE",
  "CBSH",
  "CBU",
  "CC",
  "CCCS",
  "CCEP",
  "CCI",
  "CCJ",
  "CCK",
  "CCL",
  "CCOI",
  "CD",
  "CDAY",
  "CDNS",
  "CDW",
  "CE",
  "CEF",
  "CEG",
  "CEIX",
  "CELH",
  "CEQP",
  "CERE",
  "CERT",
  "CF",
  "CFG",
  "CFLT",
  "CFR",
  "CG",
  "CGNX",
  "CHD",
  "CHDN",
  "CHH",
  "CHK",
  "CHKP",
  "CHPT",
  "CHRD",
  "CHRW",
  "CHTR",
  "CHWY",
  "CHX",
  "CI",
  "CIB",
  "CIEN",
  "CINF",
  "CIVI",
  "CL",
  "CLF",
  "CLH",
  "CLVT",
  "CLX",
  "CM",
  "CMA",
  "CMC",
  "CMCSA",
  "CME",
  "CMI",
  "CMS",
  "CNC",
  "CNHI",
  "CNI",
  "CNK",
  "CNM",
  "CNMD",
  "CNO",
  "CNP",
  "CNQ",
  "CNX",
  "CNXC",
  "COF",
  "COHR",
  "COIN",
  "COLB",
  "COLD",
  "COLM",
  "COOP",
  "COP",
  "CORT",
  "COST",
  "COTY",
  "CP",
  "CPA",
  "CPB",
  "CPG",
  "CPNG",
  "CPRI",
  "CPRT",
  "CPT",
  "CR",
  "CRC",
  "CRDO",
  "CRH",
  "CRI",
  "CRK",
  "CRL",
  "CRM",
  "CROX",
  "CRS",
  "CRSP",
  "CRUS",
  "CRWD",
  "CSAN",
  "CSCO",
  "CSGP",
  "CSIQ",
  "CSL",
  "CSTM",
  "CSX",
  "CTAS",
  "CTLT",
  "CTRA",
  "CTSH",
  "CTVA",
  "CUBE",
  "CUK",
  "CUZ",
  "CVAC",
  "CVE",
  "CVI",
  "CVLT",
  "CVNA",
  "CVS",
  "CVX",
  "CWAN",
  "CWEN",
  "CWH",
  "CX",
  "CXM",
  "CXT",
  "CYBR",
  "CYTK",
  "CZR",
  "D",
  "DAL",
  "DAN",
  "DAR",
  "DASH",
  "DAVA",
  "DB",
  "DBRG",
  "DBX",
  "DCI",
  "DD",
  "DDOG",
  "DE",
  "DECK",
  "DEI",
  "DELL",
  "DEN",
  "DEO",
  "DFS",
  "DG",
  "DGX",
  "DHI",
  "DHR",
  "DINO",
  "DIS",
  "DISH",
  "DKNG",
  "DKS",
  "DLB",
  "DLO",
  "DLR",
  "DLTR",
  "DNB",
  "DNLI",
  "DNP",
  "DNUT",
  "DOC",
  "DOCN",
  "DOCS",
  "DOCU",
  "DOV",
  "DOW",
  "DOX",
  "DPZ",
  "DQ",
  "DRI",
  "DRS",
  "DRVN",
  "DT",
  "DTE",
  "DTM",
  "DUK",
  "DUOL",
  "DV",
  "DVA",
  "DVN",
  "DXC",
  "DXCM",
  "DY",
  "E",
  "EA",
  "EBAY",
  "EBC",
  "EBR",
  "EC",
  "ECL",
  "ED",
  "EDR",
  "EDU",
  "EFX",
  "EHC",
  "EIX",
  "EL",
  "ELAN",
  "ELF",
  "ELP",
  "ELS",
  "ELV",
  "EME",
  "EMN",
  "EMR",
  "ENB",
  "ENLC",
  "ENOV",
  "ENPH",
  "ENR",
  "ENSG",
  "ENTG",
  "ENV",
  "ENVX",
  "EOG",
  "EPAM",
  "EPC",
  "EPD",
  "EPR",
  "EPRT",
  "EQC",
  "EQH",
  "EQIX",
  "EQNR",
  "EQR",
  "EQT",
  "ERF",
  "ERIC",
  "ERJ",
  "ES",
  "ESI",
  "ESMT",
  "ESNT",
  "ESS",
  "ESTC",
  "ET",
  "ETN",
  "ETR",
  "ETRN",
  "ETSY",
  "EURN",
  "EVH",
  "EVR",
  "EVRG",
  "EW",
  "EWBC",
  "EXAS",
  "EXC",
  "EXEL",
  "EXG",
  "EXP",
  "EXPD",
  "EXPE",
  "EXPI",
  "EXPO",
  "EXR",
  "EXTR",
  "EYE",
  "F",
  "FAF",
  "FANG",
  "FAST",
  "FBIN",
  "FBP",
  "FCPT",
  "FCX",
  "FDX",
  "FE",
  "FERG",
  "FFIN",
  "FFIV",
  "FHB",
  "FHI",
  "FHN",
  "FIBK",
  "FIS",
  "FISV",
  "FITB",
  "FIVE",
  "FIVN",
  "FL",
  "FLEX",
  "FLNC",
  "FLO",
  "FLR",
  "FLS",
  "FLT",
  "FLYW",
  "FMC",
  "FMS",
  "FMX",
  "FN",
  "FNB",
  "FND",
  "FNF",
  "FNV",
  "FOCS",
  "FOLD",
  "FORM",
  "FOUR",
  "FOX",
  "FOXA",
  "FR",
  "FRO",
  "FROG",
  "FRPT",
  "FRSH",
  "FRT",
  "FSK",
  "FSLR",
  "FSLY",
  "FSS",
  "FTAI",
  "FTCH",
  "FTDR",
  "FTI",
  "FTNT",
  "FTS",
  "FTV",
  "FUL",
  "FULT",
  "FUTU",
  "FWONK",
  "FYBR",
  "G",
  "GBCI",
  "GBDC",
  "GD",
  "GDDY",
  "GDRX",
  "GE",
  "GEHC",
  "GEN",
  "GETY",
  "GFI",
  "GFL",
  "GFS",
  "GGAL",
  "GGB",
  "GGG",
  "GH",
  "GIL",
  "GILD",
  "GIS",
  "GKOS",
  "GL",
  "GLBE",
  "GLNG",
  "GLOB",
  "GLPI",
  "GLW",
  "GM",
  "GMAB",
  "GME",
  "GMED",
  "GNRC",
  "GNTX",
  "GNW",
  "GO",
  "GOGO",
  "GOLD",
  "GOLF",
  "GOOG",
  "GOOGL",
  "GOOS",
  "GPC",
  "GPK",
  "GPN",
  "GPS",
  "GRBK",
  "GRFS",
  "GRMN",
  "GS",
  "GSK",
  "GT",
  "GTES",
  "GTLB",
  "GTLS",
  "GWRE",
  "GXO",
  "H",
  "HAL",
  "HALO",
  "HAS",
  "HASI",
  "HAYW",
  "HBAN",
  "HCA",
  "HCP",
  "HD",
  "HDB",
  "HE",
  "HEI",
  "HELE",
  "HES",
  "HESM",
  "HGV",
  "HI",
  "HIG",
  "HII",
  "HIW",
  "HL",
  "HLI",
  "HLN",
  "HLT",
  "HMC",
  "HOG",
  "HOLX",
  "HOMB",
  "HON",
  "HOOD",
  "HP",
  "HPE",
  "HPQ",
  "HQY",
  "HR",
  "HRB",
  "HRI",
  "HRL",
  "HRMY",
  "HSBC",
  "HSIC",
  "HST",
  "HSY",
  "HTGC",
  "HTH",
  "HTHT",
  "HTZ",
  "HUBB",
  "HUBS",
  "HUM",
  "HUN",
  "HWC",
  "HWM",
  "HXL",
  "HZNP",
  "IAC",
  "IART",
  "IAS",
  "IAU",
  "IBKR",
  "IBM",
  "IBN",
  "ICE",
  "ICL",
  "ICLR",
  "IDCC",
  "IDXX",
  "IEP",
  "IEX",
  "IFF",
  "IGT",
  "ILMN",
  "IMGN",
  "IMO",
  "IMVT",
  "INCY",
  "INFA",
  "INFY",
  "ING",
  "INGR",
  "INMD",
  "INSM",
  "INSP",
  "INTA",
  "INTC",
  "INTU",
  "INVH",
  "IONQ",
  "IONS",
  "IOT",
  "IP",
  "IPG",
  "IQV",
  "IR",
  "IRDM",
  "IRM",
  "IRT",
  "IRTC",
  "ISRG",
  "IT",
  "ITCI",
  "ITT",
  "ITUB",
  "ITW",
  "IVZ",
  "J",
  "JAMF",
  "JAZZ",
  "JBHT",
  "JBL",
  "JBLU",
  "JCI",
  "JD",
  "JEF",
  "JHG",
  "JKHY",
  "JKS",
  "JLL",
  "JNJ",
  "JNPR",
  "JOBY",
  "JPM",
  "JWN",
  "JXN",
  "K",
  "KBH",
  "KBR",
  "KD",
  "KDP",
  "KEX",
  "KEY",
  "KEYS",
  "KHC",
  "KIM",
  "KKR",
  "KLAC",
  "KLIC",
  "KMB",
  "KMI",
  "KMPR",
  "KMT",
  "KMX",
  "KNX",
  "KO",
  "KOS",
  "KR",
  "KRC",
  "KRG",
  "KRTX",
  "KSS",
  "KT",
  "KTB",
  "KVUE",
  "KW",
  "L",
  "LAC",
  "LAD",
  "LAMR",
  "LAZ",
  "LAZR",
  "LBRDK",
  "LBRT",
  "LBTYA",
  "LBTYK",
  "LCID",
  "LDOS",
  "LEA",
  "LECO",
  "LEG",
  "LEGN",
  "LEN",
  "LEVI",
  "LFST",
  "LGF-A",
  "LGF-B",
  "LH",
  "LHX",
  "LI",
  "LII",
  "LIN",
  "LITE",
  "LIVN",
  "LKQ",
  "LLY",
  "LMT",
  "LNC",
  "LNG",
  "LNT",
  "LNTH",
  "LNW",
  "LOGI",
  "LOW",
  "LPLA",
  "LPX",
  "LRCX",
  "LSCC",
  "LSI",
  "LSPD",
  "LSXMA",
  "LSXMK",
  "LTH",
  "LTHM",
  "LULU",
  "LUV",
  "LVS",
  "LW",
  "LXP",
  "LYB",
  "LYFT",
  "LYV",
  "LZ",
  "M",
  "MA",
  "MAA",
  "MAC",
  "MAIN",
  "MAN",
  "MANH",
  "MANU",
  "MAR",
  "MAS",
  "MASI",
  "MAT",
  "MATX",
  "MBLY",
  "MC",
  "MCD",
  "MCHP",
  "MCK",
  "MCO",
  "MCW",
  "MDB",
  "MDC",
  "MDGL",
  "MDLZ",
  "MDT",
  "MDU",
  "MEDP",
  "MELI",
  "MET",
  "META",
  "MFC",
  "MGA",
  "MGM",
  "MGY",
  "MHK",
  "MHO",
  "MIDD",
  "MKC",
  "MKSI",
  "MKTX",
  "MLCO",
  "MLI",
  "MLM",
  "MMC",
  "MMM",
  "MMP",
  "MMS",
  "MMYT",
  "MNDY",
  "MNSO",
  "MNST",
  "MO",
  "MODG",
  "MOH",
  "MORF",
  "MOS",
  "MP",
  "MPC",
  "MPLX",
  "MPW",
  "MPWR",
  "MRCY",
  "MRK",
  "MRNA",
  "MRO",
  "MRTX",
  "MRVI",
  "MRVL",
  "MS",
  "MSCI",
  "MSFT",
  "MSI",
  "MSM",
  "MSTR",
  "MT",
  "MTB",
  "MTCH",
  "MTDR",
  "MTG",
  "MTH",
  "MTN",
  "MTSI",
  "MTZ",
  "MU",
  "MUFG",
  "MUR",
  "MWA",
  "MXL",
  "NABL",
  "NAD",
  "NARI",
  "NATI",
  "NAVI",
  "NBIX",
  "NCLH",
  "NCNO",
  "NCR",
  "NDAQ",
  "NE",
  "NEA",
  "NEE",
  "NEM",
  "NEO",
  "NEOG",
  "NEP",
  "NET",
  "NEWR",
  "NFE",
  "NFG",
  "NFLX",
  "NG",
  "NGG",
  "NI",
  "NICE",
  "NIO",
  "NJR",
  "NKE",
  "NLY",
  "NMIH",
  "NNN",
  "NOC",
  "NOG",
  "NOMD",
  "NOV",
  "NOVA",
  "NOW",
  "NRG",
  "NSA",
  "NSC",
  "NSIT",
  "NTAP",
  "NTCO",
  "NTCT",
  "NTES",
  "NTLA",
  "NTNX",
  "NTR",
  "NTRA",
  "NTRS",
  "NU",
  "NUE",
  "NUVA",
  "NVCR",
  "NVDA",
  "NVEI",
  "NVG",
  "NVO",
  "NVS",
  "NVST",
  "NVT",
  "NWG",
  "NWL",
  "NWS",
  "NWSA",
  "NXPI",
  "NXST",
  "NXT",
  "NYCB",
  "NYT",
  "O",
  "OC",
  "ODFL",
  "OFC",
  "OGE",
  "OGN",
  "OGS",
  "OHI",
  "OI",
  "OKE",
  "OKTA",
  "OLED",
  "OLLI",
  "OLN",
  "OMC",
  "OMCL",
  "OMF",
  "ON",
  "ONB",
  "ONON",
  "OPCH",
  "OR",
  "ORA",
  "ORAN",
  "ORCC",
  "ORCL",
  "ORI",
  "ORLY",
  "OSCR",
  "OSK",
  "OTEX",
  "OTIS",
  "OUT",
  "OVV",
  "OWL",
  "OXY",
  "OZK",
  "PAA",
  "PAAS",
  "PACB",
  "PAG",
  "PAGP",
  "PAGS",
  "PANW",
  "PARA",
  "PATH",
  "PAYC",
  "PAYX",
  "PB",
  "PBA",
  "PBF",
  "PBR",
  "PBR-A",
  "PCAR",
  "PCG",
  "PCH",
  "PCOR",
  "PCTY",
  "PCVX",
  "PD",
  "PDCE",
  "PDCO",
  "PDD",
  "PDI",
  "PEAK",
  "PECO",
  "PEG",
  "PEN",
  "PENN",
  "PEP",
  "PFE",
  "PFG",
  "PFGC",
  "PFSI",
  "PG",
  "PGNY",
  "PGR",
  "PH",
  "PHG",
  "PHM",
  "PHYS",
  "PI",
  "PII",
  "PINC",
  "PINS",
  "PK",
  "PKG",
  "PKX",
  "PLD",
  "PLNT",
  "PLTK",
  "PLTR",
  "PLUG",
  "PM",
  "PNC",
  "PNFP",
  "PNM",
  "PNR",
  "PNW",
  "PODD",
  "POOL",
  "POR",
  "POST",
  "POWI",
  "PPC",
  "PPG",
  "PPL",
  "PR",
  "PRGO",
  "PRGS",
  "PRMW",
  "PRTA",
  "PRU",
  "PRVA",
  "PSA",
  "PSEC",
  "PSLV",
  "PSN",
  "PSO",
  "PSTG",
  "PSX",
  "PTC",
  "PTCT",
  "PTEN",
  "PTON",
  "PUK",
  "PVH",
  "PWR",
  "PWSC",
  "PXD",
  "PYCR",
  "PYPL",
  "PZZA",
  "QCOM",
  "QDEL",
  "QFIN",
  "QGEN",
  "QLYS",
  "QRVO",
  "QS",
  "QSR",
  "RACE",
  "RARE",
  "RBA",
  "RBLX",
  "RCI",
  "RCL",
  "RCM",
  "RDN",
  "RE",
  "REG",
  "REGN",
  "RELX",
  "RELY",
  "RETA",
  "REXR",
  "REYN",
  "REZI",
  "RF",
  "RGA",
  "RGEN",
  "RGLD",
  "RH",
  "RHI",
  "RHP",
  "RIG",
  "RIO",
  "RITM",
  "RIVN",
  "RJF",
  "RKLB",
  "RKT",
  "RL",
  "RMBS",
  "RMD",
  "RNG",
  "RNR",
  "ROIV",
  "ROK",
  "ROKU",
  "ROL",
  "ROP",
  "ROST",
  "RPD",
  "RPM",
  "RPRX",
  "RRC",
  "RRR",
  "RRX",
  "RS",
  "RSG",
  "RTO",
  "RTX",
  "RUM",
  "RUN",
  "RVMD",
  "RVNC",
  "RVTY",
  "RXO",
  "RY",
  "RYAAY",
  "RYAN",
  "RYN",
  "S",
  "SAGE",
  "SAIA",
  "SAIC",
  "SANM",
  "SAP",
  "SBAC",
  "SBRA",
  "SBS",
  "SBSW",
  "SBUX",
  "SCCO",
  "SCHW",
  "SCI",
  "SCPL",
  "SDA",
  "SDGR",
  "SE",
  "SEAS",
  "SEDG",
  "SEE",
  "SEIC",
  "SEM",
  "SF",
  "SFM",
  "SFNC",
  "SGEN",
  "SGML",
  "SGRY",
  "SHAK",
  "SHC",
  "SHEL",
  "SHLS",
  "SHO",
  "SHOO",
  "SHOP",
  "SHW",
  "SIG",
  "SIL",
  "SIMO",
  "SITC",
  "SITM",
  "SIX",
  "SJM",
  "SKM",
  "SKT",
  "SKX",
  "SKY",
  "SLAB",
  "SLB",
  "SLF",
  "SLGN",
  "SLM",
  "SM",
  "SMAR",
  "SMCI",
  "SMFG",
  "SMG",
  "SMPL",
  "SNA",
  "SNAP",
  "SNDR",
  "SNN",
  "SNOW",
  "SNPS",
  "SNV",
  "SNX",
  "SNY",
  "SO",
  "SOFI",
  "SON",
  "SONY",
  "SPB",
  "SPG",
  "SPGI",
  "SPLK",
  "SPOT",
  "SPR",
  "SPT",
  "SQ",
  "SQM",
  "SQSP",
  "SRC",
  "SRCL",
  "SRE",
  "SRPT",
  "SSB",
  "SSNC",
  "SSRM",
  "ST",
  "STAA",
  "STAG",
  "STE",
  "STEP",
  "STLA",
  "STLD",
  "STM",
  "STNE",
  "STNG",
  "STR",
  "STT",
  "STWD",
  "STX",
  "STZ",
  "SU",
  "SUI",
  "SUM",
  "SUZ",
  "SWAV",
  "SWK",
  "SWKS",
  "SWN",
  "SWX",
  "SYF",
  "SYK",
  "SYM",
  "SYNA",
  "SYNH",
  "SYY",
  "T",
  "TAK",
  "TAL",
  "TAP",
  "TCBI",
  "TCN",
  "TCOM",
  "TD",
  "TDC",
  "TDOC",
  "TDW",
  "TEAM",
  "TECH",
  "TECK",
  "TEL",
  "TENB",
  "TER",
  "TEVA",
  "TEX",
  "TFC",
  "TFSL",
  "TFX",
  "TGNA",
  "TGT",
  "TGTX",
  "THC",
  "THO",
  "THS",
  "TIMB",
  "TJX",
  "TKR",
  "TMDX",
  "TME",
  "TMHC",
  "TMO",
  "TMUS",
  "TNET",
  "TNL",
  "TOL",
  "TOST",
  "TPG",
  "TPH",
  "TPR",
  "TPX",
  "TREX",
  "TRGP",
  "TRI",
  "TRIP",
  "TRMB",
  "TRMD",
  "TRNO",
  "TROW",
  "TRP",
  "TRTN",
  "TRU",
  "TRV",
  "TS",
  "TSCO",
  "TSEM",
  "TSLA",
  "TSM",
  "TSN",
  "TT",
  "TTC",
  "TTD",
  "TTE",
  "TTWO",
  "TU",
  "TV",
  "TW",
  "TWKS",
  "TWLO",
  "TWNK",
  "TXG",
  "TXN",
  "TXRH",
  "TXT",
  "U",
  "UA",
  "UAA",
  "UAL",
  "UBER",
  "UBS",
  "UBSI",
  "UCBI",
  "UDR",
  "UFPI",
  "UGI",
  "UHAL-B",
  "UHS",
  "UL",
  "ULTA",
  "UMBF",
  "UMC",
  "UNH",
  "UNM",
  "UNP",
  "UNVR",
  "UPS",
  "UPST",
  "URBN",
  "URI",
  "USB",
  "USFD",
  "UTHR",
  "UTZ",
  "UWMC",
  "V",
  "VAC",
  "VAL",
  "VALE",
  "VC",
  "VCTR",
  "VEEV",
  "VERX",
  "VET",
  "VFC",
  "VIAV",
  "VICI",
  "VICR",
  "VIPS",
  "VIR",
  "VIRT",
  "VIST",
  "VIV",
  "VKTX",
  "VLO",
  "VLY",
  "VMC",
  "VMW",
  "VNO",
  "VNOM",
  "VNT",
  "VOD",
  "VOYA",
  "VRNS",
  "VRNT",
  "VRRM",
  "VRSK",
  "VRSN",
  "VRT",
  "VRTX",
  "VSAT",
  "VSH",
  "VST",
  "VTR",
  "VTRS",
  "VVV",
  "VZ",
  "W",
  "WAB",
  "WAL",
  "WAT",
  "WB",
  "WBA",
  "WBD",
  "WBS",
  "WCC",
  "WCN",
  "WDAY",
  "WDC",
  "WDS",
  "WEC",
  "WELL",
  "WEN",
  "WERN",
  "WES",
  "WEX",
  "WFC",
  "WFRD",
  "WH",
  "WHD",
  "WHR",
  "WING",
  "WIX",
  "WK",
  "WLK",
  "WM",
  "WMB",
  "WMG",
  "WMS",
  "WMT",
  "WOLF",
  "WOOF",
  "WPC",
  "WPM",
  "WRB",
  "WRK",
  "WSC",
  "WSFS",
  "WSM",
  "WSO",
  "WST",
  "WTFC",
  "WTRG",
  "WTW",
  "WU",
  "WWD",
  "WWE",
  "WY",
  "WYNN",
  "X",
  "XEL",
  "XENE",
  "XOM",
  "XP",
  "XPEV",
  "XPO",
  "XRAY",
  "XRX",
  "XYL",
  "YELP",
  "YETI",
  "YMM",
  "YOU",
  "YPF",
  "YUM",
  "YUMC",
  "Z",
  "ZBH",
  "ZBRA",
  "ZD",
  "ZG",
  "ZGN",
  "ZI",
  "ZION",
  "ZLAB",
  "ZM",
  "ZS",
  "ZTO",
  "ZTS",
  "ZWS"
]; // List of ticker symbols
export default tickerSymbols;
