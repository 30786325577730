import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { StytchProvider, initStytch } from "@stytch/stytch-react";
import React from "react";

import App from "./App";

const stytch = initStytch(
  "public-token-test-f7a95d82-2870-48b7-a83a-ed652c02c4d4"
);
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <StytchProvider stytch={stytch}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StytchProvider>
  </React.StrictMode>
);
