import { Link } from "react-router-dom";
import "./TableStyles.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MacroPageContent from "./MacroPageContent";

import { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField, // Import TextField for user input
  Button,
  Checkbox, // Import Button for submission
} from "@mui/material";
import { Slider, Typography, Tooltip } from "@mui/material";

import supabase from "../Supabase/supabase"; // Assuming you have the supabase client imported correctly

function Screener() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [selectedDate, setSelectedDate] = useState(yesterday);

  const [rowData, setRowData] = useState(null);

  const [d1_ma200_positive, setd1_ma200_positive] = useState(true);  
  const toggled1_ma200_positive = () => {
    setd1_ma200_positive(!d1_ma200_positive);
  };
  const [thirty_pct_rally_off_lows, setThirty_pct_rally_off_lows] = useState(false);
  const togglethirty_pct_rally_off_lows = () => {
    setThirty_pct_rally_off_lows(!thirty_pct_rally_off_lows);
  };
  const [ twenty_pct_off_1y_high, setTwenty_pct_off_1y_high] = useState(false);
  const toggletwenty_pct_off_1y_high = () => {
    setTwenty_pct_off_1y_high(!twenty_pct_off_1y_high);
  };
  const [close_over_50, setclose_over_50] = useState(true);
  const toggleclose_over_50 = () => {
    setclose_over_50(!close_over_50);
  };
  const [close_over_150_and_200, setclose_over_150_and_200] = useState(true);
  const toggleclose_over_150_and_200 = () => {
    setclose_over_150_and_200(!close_over_150_and_200);
  };

  const [higher_lows, sethigher_lows] = useState(false);
  const togglehigher_lows = () => {
    sethigher_lows(!higher_lows);
  };
  const [higher_highs, sethigher_highs] = useState(false);
  const togglehigher_highs = () => {
    sethigher_highs(!higher_highs);
  };
  const [higher_volume_on_up_days_than_down_days, sethigher_volume_on_up_days_than_down_days] = useState(false);
  const togglehigher_volume_on_up_days_than_down_days = () => {
    sethigher_volume_on_up_days_than_down_days(!higher_volume_on_up_days_than_down_days);
  };
  const [ higher_volume_on_up_trend_than_down_trend, sethigher_volume_on_up_trend_than_down_trend] = useState(false);
  const togglehigher_volume_on_up_trend_than_down_trend = () => {
    sethigher_volume_on_up_trend_than_down_trend(!higher_volume_on_up_trend_than_down_trend);
  };
  const [highest_volume_year, sethighest_volume_year] = useState(false);
  const togglehighest_volume_year = () => {
    sethighest_volume_year(!highest_volume_year);
  };
  const [three_days_tight, setthree_days_tight] = useState(false);
  const togglethree_days_tight = () => {
    setthree_days_tight(!three_days_tight);
  };
  const [ five_days_tight, setfive_days_tight] = useState(false);
  const togglefive_days_tight = () => {
    setfive_days_tight(!five_days_tight);
  };
  const [relative_volatility, setrelative_volatility] = useState(false);
  const togglerelative_volatility = () => {
    setrelative_volatility(!relative_volatility);
  };



  const [ median_adr, setmedian_adr] = useState(false);
  const togglemedian_adr = () => {
    setmedian_adr(!median_adr);
  };
  const [launch_pad, setlaunch_pad] = useState(false);
  const togglelaunch_pad = () => {
    setlaunch_pad(!launch_pad);
  };

  const [three_yoy_qtrs_20_pct_growth_eps, setthree_yoy_qtrs_20_pct_growth_eps] = useState(false);
  const togglethree_yoy_qtrs_20_pct_growth_eps = () => {
    setthree_yoy_qtrs_20_pct_growth_eps(!three_yoy_qtrs_20_pct_growth_eps);
  };
const [two_yoy_qtrs_60_pct_growth_eps, settwo_yoy_qtrs_60_pct_growth_eps] = useState(false);
const toggletwo_yoy_qtrs_60_pct_growth_eps = () => {
  settwo_yoy_qtrs_60_pct_growth_eps(!two_yoy_qtrs_60_pct_growth_eps);
};
const [ three_qoq_growth_in_eps_growth, setthree_qoq_growth_in_eps_growth] = useState(false);
const togglethree_qoq_growth_in_eps_growth = () => {
  setthree_qoq_growth_in_eps_growth(!three_qoq_growth_in_eps_growth);
};
const [ three_qtrs_10_pct_growth_eps, setthree_qtrs_10_pct_growth_eps] = useState(false);
const togglethree_qtrs_10_pct_growth_eps = () => {
  setthree_qtrs_10_pct_growth_eps(!three_qtrs_10_pct_growth_eps);
};
  const [eps_up_revision_one_month, seteps_up_revision_one_month] = useState(false);
  const toggleeps_up_revision_one_month = () => {
    seteps_up_revision_one_month(!eps_up_revision_one_month);
  };
  const [positive_surprises_past_3of4_qtrs, setpositive_surprises_past_3of4_qtrs] = useState(false);
  const togglepositive_surprises_past_3of4_qtrs = () => {
    setpositive_surprises_past_3of4_qtrs(!positive_surprises_past_3of4_qtrs);
  };
  const [growing_future_eps_est, setgrowing_future_eps_est] = useState(false);
  const togglegrowing_future_eps_est = () => {
    setgrowing_future_eps_est(!growing_future_eps_est);
  };
  const [twelve_week_relative_strength, settwelve_week_relative_strength] = useState(false);
  const toggletwelve_week_relative_strength = () => {
    settwelve_week_relative_strength(!twelve_week_relative_strength);
  };
  const [fiftytwo_week_relative_strength, setfiftytwo_week_relative_strength] = useState(false);
  const togglefiftytwo_week_relative_strength = () => {
    setfiftytwo_week_relative_strength(!fiftytwo_week_relative_strength);
  };
  const [bull_flag, setbull_flag] = useState(false);
  const togglebull_flag = () => {
    setbull_flag(!bull_flag);
  };
  const [vcp, setvcp] = useState(false);
  const togglevcp = () => {
    setvcp(!vcp);
  };

  const [selectedSectors, setSelectedSectors] = useState([]);

  const toggleSector = (sector) => {
    if (selectedSectors.includes(sector)) {
      setSelectedSectors(selectedSectors.filter((s) => s !== sector));
    } else {
      setSelectedSectors([...selectedSectors, sector]);
    }
  };


  useEffect(() => {
    fetchTableData(formatDate(selectedDate));
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateSubmit = async () => {
    if (selectedDate) {
      await fetchTableData(formatDate(selectedDate)); // Format and fetch data
      // console.log("Submitted Date:", formatDate(selectedDate)); // Log formatted date
    } else {
      // console.log("No date selected");
    }
  };
  
  const fetchTableData = async (desiredDate) => {
    let query = supabase.from("screened_stocks").select("symbol, asofdate").eq("asofdate", desiredDate);
    console.log("selectedSectors", selectedSectors); // Log formatted date
    
    if (selectedSectors.length > 0) {
      query = query.in("sector", selectedSectors);
    }
  
    // Define an array of filter variables
    const filterVariables = [
      "d1_ma200_positive",
      "thirty_pct_rally_off_lows",
      "twenty_pct_off_1y_high",
      "close_over_50",
      "close_over_150_and_200",
      "higher_lows",
      "higher_highs",
      "higher_volume_on_up_days_than_down_days",
      "higher_volume_on_up_trend_than_down_trend",
      "highest_volume_year",
      "three_days_tight",
      "five_days_tight",
      "relative_volatility",
      "three_yoy_qtrs_20_pct_growth_eps",
      "two_yoy_qtrs_60_pct_growth_eps",
      "three_qoq_growth_in_eps",
      "three_qtrs_10_pct_growth_eps",
      "eps_up_revision_one_month",
      "positive_surprises_past_3of4_qtrs",
      "growing_future_eps_est",
      "twelve_week_relative_strength",
      "fiftytwo_week_relative_strength",
      "bull_flag",
      "vcp",
      "launch_pad",
      "median_adr"
    ];
  
    // Loop through each filter variable and apply the filter if its value is true
    filterVariables.forEach(variable => {
      const value = eval(variable); // Get the value of the variable dynamically
      // console.log("variable values:", variable, value); // Log formatted date
      if (value) {
        query = query.eq(variable, true);
      }
    });
  
    const { data, error } = await query;
  
    setRowData(data);
  };
  

  const formatNumberToTwoDecimals = (number) => {
    return parseFloat(number).toFixed(2);
  };

  const inputFields_MA = [
    {
      value: d1_ma200_positive,
      setValue: toggled1_ma200_positive,
      className: d1_ma200_positive ? "active-button" : "inactive-button",
      buttonText: "Positive 200 SMA slope",
    },
    {
      value: thirty_pct_rally_off_lows,
      setValue: togglethirty_pct_rally_off_lows,
      className: thirty_pct_rally_off_lows ? "active-button" : "inactive-button",
      buttonText: "30%+ off 52 week Low",
    },
    {
      value: twenty_pct_off_1y_high ,
      setValue: toggletwenty_pct_off_1y_high ,
      className: twenty_pct_off_1y_high  ? "active-button" : "inactive-button",
      buttonText: "20%- off 52 week high",
    },
    {
      value: close_over_50,
      setValue: toggleclose_over_50,
      className: close_over_50 ? "active-button" : "inactive-button",
      buttonText: "Close over 50 SMA",
    },
    {
      value: close_over_150_and_200,
      setValue: toggleclose_over_150_and_200,
      className: close_over_150_and_200 ? "active-button" : "inactive-button",
      buttonText: "Close above 150 above 200 SMA",
    }
  ];
  const inputFields_relative_strength = [
    {
      value: twelve_week_relative_strength,
      setValue: toggletwelve_week_relative_strength,
      className: twelve_week_relative_strength ? "active-button" : "inactive-button",
      buttonText: "3 Month Relative Strength",
    },
    {
      value: fiftytwo_week_relative_strength,
      setValue: togglefiftytwo_week_relative_strength,
      className: fiftytwo_week_relative_strength ? "active-button" : "inactive-button",
      buttonText: "12 Month Relative Strength",
    }
  ];
  const inputFields_volume = [
    {
      value: higher_lows,
      setValue: togglehigher_lows,
      className: higher_lows ? "active-button" : "inactive-button",
      buttonText: "Higher Lows",
    },
    {
      value: higher_highs,
      setValue: togglehigher_highs,
      className: higher_highs ? "active-button" : "inactive-button",
      buttonText: "Higher Highs",
    },
    {
      value: higher_volume_on_up_days_than_down_days,
      setValue: togglehigher_volume_on_up_days_than_down_days,
      className: higher_volume_on_up_days_than_down_days ? "active-button" : "inactive-button",
      buttonText: "Higher Volume on Up days than Down days",
    },
    {
      value: higher_volume_on_up_trend_than_down_trend,
      setValue: togglehigher_volume_on_up_trend_than_down_trend,
      className: higher_volume_on_up_trend_than_down_trend ? "active-button" : "inactive-button",
      buttonText: "Higher Volume on uptrend than Downtrend",
    },
    {
      value: highest_volume_year,
      setValue: togglehighest_volume_year,
      className: highest_volume_year ? "active-button" : "inactive-button",
      buttonText: "HVY in past Month",
    }
  ];
  const inputFields_trends = [
    {
      value: three_days_tight,
      setValue: togglethree_days_tight,
      className: three_days_tight ? "active-button" : "inactive-button",
      buttonText: "Three Tight",
    },
    {
      value: five_days_tight,
      setValue: togglefive_days_tight,
      className: five_days_tight ? "active-button" : "inactive-button",
      buttonText: "Five Tight",
    },
    {
      value: relative_volatility,
      setValue: togglerelative_volatility,
      className: relative_volatility ? "active-button" : "inactive-button",
      buttonText: "Relative Volatility",
    },
    {
      value: median_adr,
      setValue: togglemedian_adr,
      className: median_adr ? "active-button" : "inactive-button",
      buttonText: "ADR",
    },
    {
      value: launch_pad,
      setValue: togglelaunch_pad,
      className: launch_pad ? "active-button" : "inactive-button",
      buttonText: "Launch Pad",
    }
  ];
  const inputFields_fundamentals_1 = [
    {
      value: three_yoy_qtrs_20_pct_growth_eps,
      setValue: togglethree_yoy_qtrs_20_pct_growth_eps,
      className: three_yoy_qtrs_20_pct_growth_eps ? "active-button" : "inactive-button",
      buttonText: "3 Qtrs 20%+ Growth EPS",
    },
    {
      value: three_yoy_qtrs_20_pct_growth_revenue,
      setValue: togglethree_yoy_qtrs_20_pct_growth_revenue,
      className: three_yoy_qtrs_20_pct_growth_revenue ? "active-button" : "inactive-button",
      buttonText: "3 Qtrs 20%+ Growth Revenue",
    },
    {
      value: two_yoy_qtrs_60_pct_growth_eps,
      setValue: toggletwo_yoy_qtrs_60_pct_growth_eps,
      className: two_yoy_qtrs_60_pct_growth_eps ? "active-button" : "inactive-button",
      buttonText: "2 Qtrs 60%+ Growth EPS",
    },
    {
      value: two_yoy_qtrs_60_pct_growth_revenue,
      setValue: toggletwo_yoy_qtrs_60_pct_growth_revenue,
      className: two_yoy_qtrs_60_pct_growth_revenue ? "active-button" : "inactive-button",
      buttonText: "2 Qtrs 60%+ Growth Revenue",
    },
  ];
  const inputFields_fundamentals_2 = [
    {
      value: three_yoy_qtrs_20_pct_growth_net_profit,
      setValue: togglethree_yoy_qtrs_20_pct_growth_net_profit,
      className: three_yoy_qtrs_20_pct_growth_net_profit ? "active-button" : "inactive-button",
      buttonText: "3 Qtrs 20%+ growth Net Profit",
    },
    { 
      value: three_yoy_qtrs_5_pct_growth_net_margin,
      setValue: togglethree_yoy_qtrs_5_pct_growth_net_margin,
      className: three_yoy_qtrs_5_pct_growth_net_margin ? "active-button" : "inactive-button",
      buttonText: "3 Qtrs 5%+ NPM Growth",
    },    
    {
      value: three_qoq_growth_in_eps_growth,
      setValue: togglethree_qoq_growth_in_eps_growth,
      className: three_qoq_growth_in_eps_growth ? "active-button" : "inactive-button",
      buttonText: "Growth in EPS Growth",
    },   
    {
      value: three_qtrs_10_pct_growth_eps,
      setValue: togglethree_qtrs_10_pct_growth_eps,
      className: three_qtrs_10_pct_growth_eps ? "active-button" : "inactive-button",
      buttonText: "3 QoQ Growth",
    },
  ];
  const inputFields_analyst_info = [
    {
      value: eps_up_revision_one_month,
      setValue: toggleeps_up_revision_one_month,
      className: eps_up_revision_one_month ? "active-button" : "inactive-button",
      buttonText: "EPS Up Revision",
    },
    {
      value: positive_surprises_past_3of4_qtrs,
      setValue: togglepositive_surprises_past_3of4_qtrs,
      className: positive_surprises_past_3of4_qtrs ? "active-button" : "inactive-button",
      buttonText: "Past 3/4 Positive Surprises",
    },
    {
      value: growing_future_eps_est,
      setValue: togglegrowing_future_eps_est,
      className: growing_future_eps_est ? "active-button" : "inactive-button",
      buttonText: "Growing future EPS Est.",
    }
  ];
  const inputFields_technical_analysis = [
    {
      value: bull_flag,
      setValue: togglebull_flag,
      className: bull_flag ? "active-button" : "inactive-button",
      buttonText: "Bull Flag",
    },
    {
      value: vcp,
      setValue: togglevcp,
      className: vcp ? "active-button" : "inactive-button",
      buttonText: "Volume Contraction Pattern",
    }
  ];
  

  const renderTable = () => {
    if (rowData) {
      if (rowData && rowData.length > 0) {
        const keys = Object.keys(rowData[0]);

        return (
          <div className="container">
            <TableContainer component={Paper} className="table-container">
              <Table className="table" aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    {keys.map((key) => (
                      <TableCell key={key} className="table-header-cell">
                        <h3>
                          {" "}
                          {key === "pct_off_atr"
                            ? "Percentage Off 20EMA"
                            : key === "max_loss"
                            ? "Max Loss"
                            : key === "symbol"
                            ? "Symbol"
                            : key === "asofdate"
                            ? "Date"
                            : key}{" "}
                        </h3>
                      </TableCell>
                    ))}
                    <TableCell className="table-header-cell">
                      <h3>Details</h3>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.map((row, index) => (
                    <TableRow key={index} className="table-row">
                      {keys.map((key) => (
                        <TableCell key={key} className="table-cell">
                          {typeof row[key] === "number"
                            ? formatNumberToTwoDecimals(row[key])
                            : row[key]}
                        </TableCell>
                      ))}
                      <TableCell className="button-cell">
                        <TableCell className="button-cell">
                          <Link to={`/${row.symbol}`}>
                            <button className="details-button">Details</button>
                          </Link>
                        </TableCell>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      } else if (rowData && rowData.length === 0) {
        // Render a message when rowData is an empty array (no data found)
        return (
          <div className="no-data-message">
            No data found for the selected date.
          </div>
        );
      } else {
        // Render a loading state when rowData is null (data is being fetched)
        return <div className="loading-message">Loading...</div>;
      }
    }
    return null; // Return null if rowData is not available
  };
  const renderInputFields = () => {
    return (
      <div className="container">
        <div className="user-input-table">
          <TableContainer component={Paper} className="input-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h3>Input Data</h3>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="Select Date">
                  <TableCell>
                    <h3>Select a Date</h3>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(new_date) => setSelectedDate(new_date)}
                      dateFormat="yyyy-MM-dd"
                      className="custom-datepicker" // Apply custom CSS class
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
  <TableCell>
    <h3>Select Sectors</h3>
    <div className="input-fields-row">
      {[
        "Real Estate",
        "Healthcare",
        "Basic Materials",
        "Energy",
        "Consumer Cyclical",
        "Industrials",
        "Utilities",
        "Financial Services",
        "Technology",
        "Consumer Defensive",
        "Communication Services"
      ].map((sector) => (
        <button
          key={sector}
          className={selectedSectors.includes(sector) ? "active-button" : "inactive-button"}
          onClick={() => toggleSector(sector)}
        >
          {sector}
        </button>
      ))}
    </div>
  </TableCell>
</TableRow>
                <TableRow>
                  <TableCell>
                    <Tooltip
                      title="" // Put the Tool tip text here
                      placement="left"
                    >
                      <Typography gutterBottom variant="subtitle2">
                        <h3>Moving Average and Stage Positing</h3>
                      </Typography>
                    </Tooltip>
                    <div className="input-fields-row">
                      {inputFields_MA.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Tooltip
                      title="" // Put the Tool tip text here
                      placement="left"
                    >
                      <Typography gutterBottom variant="subtitle2">
                        <h3>Volume and Trends</h3>
                      </Typography>
                    </Tooltip>
                    <div className="input-fields-row">
                      {inputFields_volume.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Tooltip
                      title="" // Put the Tool tip text here
                      placement="left"
                    >
                      <Typography gutterBottom variant="subtitle2">
                        <h3>Relative Strength</h3>
                      </Typography>
                    </Tooltip>
                    <div className="input-fields-row">
                      {inputFields_relative_strength.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Tooltip
                      title="" // Put the Tool tip text here
                      placement="left"
                    >
                      <Typography gutterBottom variant="subtitle2">
                        <h3>Volatility</h3>
                      </Typography>
                    </Tooltip>
                    <div className="input-fields-row">
                      {inputFields_trends.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Tooltip
                      title="" // Put the Tool tip text here
                      placement="left"
                    >
                      <Typography gutterBottom variant="subtitle2">
                        <h3>Fundamentals</h3>
                      </Typography>
                    </Tooltip>
                    <div className="input-fields-row">
                      {inputFields_fundamentals_1.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="input-fields-row">
                      {inputFields_fundamentals_2.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Tooltip
                      title="" // Put the Tool tip text here
                      placement="left"
                    >
                      <Typography gutterBottom variant="subtitle2">
                        <h3>Analyst Info</h3>
                      </Typography>
                    </Tooltip>
                    <div className="input-fields-row">
                      {inputFields_analyst_info.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Tooltip
                      title="" // Put the Tool tip text here
                      placement="left"
                    >
                      <Typography gutterBottom variant="subtitle2">
                        <h3>Technical Set-ups</h3>
                      </Typography>
                    </Tooltip>
                    <div className="input-fields-row">
                      {inputFields_technical_analysis.map((field) => (
                        <>
                          <button
                            className={field.className}
                            onClick={field.setValue}
                          >
                            {field.buttonText}
                          </button>
                        </>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={handleDateSubmit}
                      className="submit-button"
                    >
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };
  return (
    <div className="App">
      <div className="content-wrapper">
        {/* Render both sections inside a flex container */}
        <div className="table-section">{renderTable()}</div>
        <div className="input-section">{renderInputFields()}</div>
      </div>
    </div>
  );
}

export default Screener;
