import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./DetailsPageStyles.css";
import { Card, CardContent, Typography } from "@mui/material";
import TradingView from "../subComponents/TradingView";
import Fundamentals from "./Fundamentals";
import { useAuth } from "../components/AuthContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  Checkbox,
  Button
} from "@mui/material";

import supabase from "../Supabase/supabase";

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function DetailsPage() {
  const { data: user } = useAuth();
  const { symbol } = useParams();
  // Define separate state variables for price and shares
  const [priceInput, setPriceInput] = useState(0);
  const [sharesInput, setSharesInput] = useState(0);
  const [buyCheckbox, setBuyCheckbox] = useState(true);
  const [sellCheckbox, setSellCheckbox] = useState(false);
  const [resultValue, setResultValue] = useState("");
  const [notes, setNotes] = useState("");


  const [watchListCheckBox, setWatchListCheckBox] = useState(false);
  

  const handlePriceInputChange = (event) => {
    const inputValue = event.target.value;
    setPriceInput(inputValue); // Update priceInput state

    // Calculate and set the resultValue based on the input
    if (inputValue !== "") {
      const priceValue = parseFloat(inputValue); // Parse price input as a float
      setResultValue((priceValue * parseFloat(sharesInput)).toFixed(2)); // Multiply priceValue and sharesInput, then format to two decimal places
    } else {
      setResultValue(""); // Clear the result if the input is empty
    }
  };

  const handleSharesInputChange = (event) => {
    const inputValue = event.target.value;
    setSharesInput(inputValue); // Update sharesInput state

    // Calculate and set the resultValue based on the input
    if (inputValue !== "") {
      const sharesValue = parseFloat(inputValue); // Parse shares input as a float
      setResultValue((parseFloat(priceInput) * sharesValue).toFixed(2)); // Multiply priceInput and sharesValue, then format to two decimal places
    } else {
      setResultValue(""); // Clear the result if the input is empty
    }
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  // Function to handle changes in the checkbox
  const handleBuyCheckboxChange = (event) => {
    if (event.target.checked) {
      // If Buy is checked, uncheck Sell
      setBuyCheckbox(true);
      setSellCheckbox(false);
    } else {
      // If Buy is unchecked, leave Sell unchanged
      setBuyCheckbox(false);
    }
  };

  const handleSellCheckboxChange = (event) => {
    if (event.target.checked) {
      // If Sell is checked, uncheck Buy
      setSellCheckbox(true);
      setBuyCheckbox(false);
    } else {
      // If Sell is unchecked, leave Buy unchanged
      setSellCheckbox(false);
    }watchListCheckBox
  };

  const handleSubmit = async () => {
    console.log("handleSubmit Activatyed:");
    if (sharesInput > 0) {
      // Check if sharesInput is greater than zero
      try {
        const { data, error } = await supabase.from("trades").upsert([
          {
            symbol: symbol,
            // asofdate: today,
            type: buyCheckbox ? "Buy" : sellCheckbox ? "Sell" : null,
            price: priceInput,
            user: user ? user.user.providerData[0].uid : null,
            shares: sharesInput,
            notes: notes ? notes : null,
          }
        ]);

        if (error) {
          console.error("Error submitting data:", error.message);
        } else {
          // Data submitted successfully
          console.log("Data submitted:", data);

          // Clear the input fields and reset checkboxes after submission
          setSharesInput(0);
          setPriceInput(0);
          setBuyCheckbox(true);
          setSellCheckbox(false);
          setNotes("");
          setResultValue("");

          // Optionally, you can also fetch the updated trade history here if needed.
        }
      } catch (error) {
        console.error("Error submitting data:", error.message);
      }
    } else {
      alert("Shares must be greater than zero.");
    }
  };

  const [tradeHistory, setTradeHistory] = useState([]);
  const fetchTradeHistory = async () => {
    try {
      const { data, error } = await supabase
        .from("trades")
        .select("asofdate, type, price, shares, totalvalue, notes")
        .eq("symbol", symbol)
        .eq("user", user ? user.user.providerData[0].uid : null) // Filter by symbol if needed
        .order("asofdate", { ascending: false });

      if (error) {
        console.error("Error fetching trade history:", error.message);
      } else {
        setTradeHistory(data);
      }
    } catch (error) {
      console.error("Error fetching trade history:", error.message);
    }
  };

  const [holdings, setHoldings] = useState([]);
  const fetchHoldings = async () => {
    try {
      const { data, error } = await supabase
        .from("holdings")
        .select("average_price, shares, totalvalue")
        .eq("symbol", symbol)
        .eq("user", user ? user.user.providerData[0].uid : null) // Filter by symbol if needed
        .order("asofdate", { ascending: false })
        .limit(1);

      console.log("data2352345", data[0].shares);

      if (error) {
        console.error("Error fetching trade history:", error.message);
      } else {
        // Data fetched successfully, update trade history state
        setHoldings(data);
      }
    } catch (error) {
      console.error("Error fetching trade history:", error.message);
    }
  };

  useEffect(() => {
    // Fetch trade history data when the component mounts
    fetchTradeHistory();
    fetchHoldings();
  }, [symbol]);

  function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }


  //  WATCHLSIT LOGIC:

  useEffect(() => {
    const fetchWatchlist = async () => {
      try {
        const { data, error } = await supabase
          .from("watchlist")
          .select("*")
          .eq("user", user ? user.user.providerData[0].uid : null)
          .eq("symbol", symbol);

        if (error) {
          console.error("Error fetching watchlist:", error.message);
        } else {
          // If the symbol is present in the user's watchlist, set the checkbox state to true
          setWatchListCheckBox(data.length > 0);
        }
      } catch (error) {
        console.error("Error fetching watchlist:", error.message);
      }
    };

    fetchWatchlist();
  }, [user, symbol]);

  const handleWatchlistCheckboxChange = async (event) => {
    const isChecked = event.target.checked;

    try {
      if (isChecked) {
        // If the checkbox is checked, add the symbol to the user's watchlist
        await supabase.from("watchlist").upsert([
          {
            user: user.user.providerData[0].uid,
            symbol: symbol,
          },
        ]);
      } else {
        // If the checkbox is unchecked, remove the symbol from the user's watchlist
        await supabase
          .from("watchlist")
          .delete()
          .eq("user", user ? user.user.providerData[0].uid : null)
          .eq("symbol", symbol);
      }

      // Update the local state of the checkbox
      setWatchListCheckBox(isChecked);
    } catch (error) {
      console.error("Error updating watchlist:", error.message);
    }
  };









  return (
    <div>
    <div className="content-wrapper"> 
      <div className="left-side-graph">
      <div className="back-button-container">
      <Link to="/screener">
        <Button variant="contained" className="back-button">
          Back
        </Button>
      </Link>
    </div> 
          <TradingView symbol={symbol} />
          <div style={{ marginTop: '20px' }}>
            <Fundamentals/>
          </div>
      </div>
      <div className="right-side"> Fundamentals
      <React.Fragment>
    {user ? (
          <div className = "watchlist">
        <h2>Add to Watchlist</h2>
      <Checkbox
        checked={watchListCheckBox}
        onChange={handleWatchlistCheckboxChange}
      />
      </div>

    ) : (
      <div className="login-message">
         <Link to="/signup">
        <h2>Log in to track your trades</h2>
        </Link>
      </div>
    )}
  </React.Fragment>
            <React.Fragment>
              <TableContainer component={Paper} className="table-container">
                <Table>
                  <TableHead>
                    <h2>Holdings</h2>
                    <TableRow>
                      <TableCell className="table-header-cell">
                        Shares
                      </TableCell>
                      <TableCell className="table-cell">
            {holdings[0] ? holdings[0].shares : 0}
                      </TableCell>
                      <TableCell className="table-header-cell">
                        Average Price
                      </TableCell>
                      <TableCell className="table-cell">
                      {holdings[0] ? holdings[0].average_price.toFixed(2) : 0}
                      </TableCell>
                      <TableCell className="table-header-cell">
                        Total Value
                      </TableCell>
                      <TableCell className="table-cell">
                      {holdings[0] ? holdings[0].totalvalue.toFixed(2) : 0}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className="table-row">
                      <TableCell className="table-cell">Price:</TableCell>
                      <TableCell className="table-cell">
                        {/* Input box for shares */}
                        <Input
                          type="number"
                          value={priceInput}
                          onChange={handlePriceInputChange}
                          placeholder="Enter Price"
                          step="0.01" // Set step to 0.01 for two decimal places
                        />
                      </TableCell>
                      <TableCell className="table-cell">Shares:</TableCell>
                      <TableCell className="table-cell">
                        <Input
                          type="number"
                          value={sharesInput}
                          onChange={handleSharesInputChange}
                          placeholder="Enter Shares"
                        />
                      </TableCell>
                      <TableCell className="table-cell">
                        $ {resultValue}
                      </TableCell>
                      <TableCell className="table-cell">
                        <Checkbox
                          checked={buyCheckbox}
                          onChange={handleBuyCheckboxChange}
                        />
                        Buy
                      </TableCell>
                      <TableCell className="table-cell">
                        <Checkbox
                          checked={sellCheckbox}
                          onChange={handleSellCheckboxChange}
                        />
                        Sell
                      </TableCell>                      
                    </TableRow>
                    <TableRow className="table-row">
                    <TableCell className="table-cell">Notes:</TableCell>
                    <TableCell className="table-cell" colSpan={6}>
                      <Input
                        type="text"
                        value={notes}
                        onChange={handleNotesChange}
                        placeholder="Enter Notes"
                        style={{ width: '100%' }} 
                      />
                    </TableCell>
                    <div className="back-button-container">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      </div>
                    
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

   <Accordion className="custom-accordion">
  <AccordionSummary>
    {/* Add a summary or header for the accordion */}
    <Typography>Trade History</Typography>
  </AccordionSummary>
  <AccordionDetails>
              <TableContainer component={Paper} className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header-cell">Date</TableCell>
                      <TableCell className="table-header-cell">Order</TableCell>
                      <TableCell className="table-header-cell">Price</TableCell>
                      <TableCell className="table-header-cell">
                        Shares
                      </TableCell>
                      <TableCell className="table-header-cell">
                        Total Value
                      </TableCell>
                      <TableCell className="table-header-cell">Notes</TableCell>
                      <TableCell className="table-header-cell"> </TableCell>
                      <TableCell className="table-header-cell"> </TableCell>
                      <TableCell className="table-header-cell"> </TableCell>
                      <TableCell className="table-header-cell"> </TableCell>
                      <TableCell className="table-header-cell"> </TableCell>
                      <TableCell className="table-header-cell"> </TableCell>
                      <TableCell className="table-header-cell"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tradeHistory.map((trade, index) => (
                      <TableRow key={index} className="table-row">
                        <TableCell className="table-cell">
                          {formatDateTime(new Date(trade.asofdate))}
                        </TableCell>
                        <TableCell className="table-cell">
                          {trade.type}
                        </TableCell>
                        <TableCell className="table-cell">
                          {trade.price.toFixed(2)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {trade.shares}
                        </TableCell>
                        <TableCell className="table-cell">
                          {trade.totalvalue.toFixed(2)}
                        </TableCell>
                        <TableCell className="table-cell" colSpan={8}>
                          {trade.notes}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

  </AccordionDetails>
</Accordion>
            </React.Fragment>          
      </div>
    </div>
        </div>
  );
}

export default DetailsPage;
