import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HomeImg from "../assets/Background2.png";
import Footer from "../components/Footer";
import SignUpContent from "../components/SignUpContent";

function SignUp() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={HomeImg}
        title="Login"
        text=""
        url="/"
        btnClass="hide"
        buttonText="Portfolio"
      />
      <SignUpContent />
      <Footer />
    </>
  );
}
export default SignUp;
