import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ContactImg from "../assets/Background3.png";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import { useAuth } from "../components/AuthContext";

function Contact() {
  const { data } = useAuth();
  // console.log(data.user.providerData[0].uid, "user's Name");

  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={ContactImg}
        title="Contact"
        text="Contact Us"
        url="/contact"
        btnClass="hide"
        buttonText="Travel Plan"
      />
      <ContactForm />
      {/* {data.user.providerData[0].uid} */}
      <Footer />
    </>
  );
}
export default Contact;
