import "./SearchStyles.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import tickerSymbols from "../assets/tickerSymbols"; // Import the array

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const input = e.target.value.toUpperCase();
    setSearchTerm(input);

    // Filter ticker symbols that start with the input
    const filteredSuggestions = tickerSymbols.filter((symbol) =>
      symbol.startsWith(input)
    );

    // Limit suggestions to a maximum of 5
    setSuggestions(filteredSuggestions.slice(0, 5));
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]); // Clear suggestions when a suggestion is clicked
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const sanitizedInput = searchTerm.toUpperCase();
    if (tickerSymbols.includes(sanitizedInput)) {
      navigate(`/${sanitizedInput}`);
    } else {
      setError("Ticker symbol not found. Please enter a valid symbol.");
    }
  };

  return (
    <div className="from-container">
      <form onSubmit={handleSubmit}>
        <button type="submit">Search</button>
        <input
          type="text"
          placeholder="Enter Symbol"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </form>
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default SearchBar;
