import "./styles.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Ledger from "./routes/Ledger";
import Contact from "./routes/Contact";
import DetailsPage from "./routes/DetailsPage";
import Search from "./routes/Search";
import Macro from "./routes/Macro";
import Screener from "./routes/Screener";
import SignUp from "./routes/SignUp";
import { Navigation } from "@mui/icons-material";
import { AuthProvider } from "./components/AuthContext";

export default function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Screener />} />
          <Route path="/screener" element={<Screener />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ledger" element={<Ledger />} />
          <Route path="/search" element={<Search />} />
          <Route path="/macro" element={<Macro />} />
          <Route path="/:symbol" element={<DetailsPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="*" element={<p>404 Page not found</p>} />

          <Route path="/about" element={<Home />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

{
  /* <Route path="/Screener" element={<Search />} /> */
}
