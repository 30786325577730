import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HomeImg from "../assets/Background2.png";
import Footer from "../components/Footer";
import SearchContent from "../components/SearchContent";

function Contact() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={HomeImg}
        title="Stock Search"
        text=""
        url="/"
        btnClass="hide"
        buttonText="Portfolio"
      />
      <SearchContent />
      <Footer />
    </>
  );
}
export default Contact;
