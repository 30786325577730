export const MenuItems = [
  {
    title: "Screener",
    url: "/screener",
    cName: "nav-links",
    icon: "fa-sharp fa-solid  fa-arrow-trend-up"
  },
  {
    title: "Ledger",
    url: "/ledger",
    cName: "nav-links",
    icon: "fa-solid fa-pen-to-square"
    //icon: "fa-solid fa-note-sticky"
  },
  {
    title: "Search",
    url: "/search",
    cName: "nav-links",
    icon: "fa-solid fa-magnifying-glass"
  },
  {
    title: "Macro",
    url: "/macro",
    cName: "nav-links",
    icon: "fa-solid fa-globe"
  },
  // {
  //   title: "Sign-Up",
  //   url: "/signup",
  //   cName: "nav-links"
  // }
];
