import React, { useState, useEffect } from "react";
import supabase from "../Supabase/supabase";
import "./DetailsPageStyles.css";
import { useParams } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import Plot from "react-plotly.js"; // Import Plot from "react-plotly.js"

function FundamentalsPage() {
  const { symbol } = useParams(); // Get the symbol from the URL using React Router

  const [fundamentalsData, setFundamentalsData] = useState({});
  const [revenueData, setRevenueData] = useState([]);
  const [netProfitData, setNetProfitData] = useState([]);
  const [netProfitMarginData, setNetProfitMarginData] = useState([]);
  const [operatingExpensesData, setOperatingExpensesData] = useState([]);

  useEffect(() => {
    // Fetch all data for the given symbol
    fetchData(symbol);
  }, [symbol]);

  const fetchData = async (symbol) => {
    // Fetch all data for the given symbol
    const { data: fundamentalsData, error: fundamentalsError } = await supabase
      .from("fundamentals")
      .select("*")
      .eq("symbol", symbol)
      .order("asofdate", { ascending: false });

    // Check for errors
    if (fundamentalsError) {
      console.error("Error fetching fundamentals data:", fundamentalsError.message);
      return;
    }

    // Process the data
    const mostRecentFundamentalsData = fundamentalsData[0]; // Assumes data is sorted by date
    setFundamentalsData(mostRecentFundamentalsData);

    // Extract the 'revenue', 'net_profit', 'net_profit_margin', and 'operating_expenses' data
    const revenueXAxisData = fundamentalsData.map((entry) => entry.asofdate);
    const revenueYAxisData = fundamentalsData.map((entry) => entry.revenue);

    const netProfitXAxisData = fundamentalsData.map((entry) => entry.asofdate);
    const netProfitYAxisData = fundamentalsData.map((entry) => entry.net_profit);

    const netProfitMarginXAxisData = fundamentalsData.map((entry) => entry.asofdate);
    const netProfitMarginYAxisData = fundamentalsData.map((entry) => entry.net_profit_margin);

    const operatingExpensesXAxisData = fundamentalsData.map((entry) => entry.asofdate);
    const operatingExpensesYAxisData = fundamentalsData.map((entry) => entry.operating_expenses);

    // Set revenue, net_profit, net_profit_margin, and operating_expenses data in state
    setRevenueData({ xAxisData: revenueXAxisData, yAxisData: revenueYAxisData });
    setNetProfitData({ xAxisData: netProfitXAxisData, yAxisData: netProfitYAxisData });
    setNetProfitMarginData({ xAxisData: netProfitMarginXAxisData, yAxisData: netProfitMarginYAxisData });
    setOperatingExpensesData({ xAxisData: operatingExpensesXAxisData, yAxisData: operatingExpensesYAxisData });
  };

  console.log("netProfitData.yAxisData", netProfitData.yAxisData)

  const shouldDisplayGraph = (data) => {
    return data.xAxisData && data.yAxisData && data.xAxisData.length >= 2 && data.yAxisData.some(value => value !== null);
  };

  return (
    <div>
    <div> <h2>Fundamentals</h2> </div>
      <div className="content-wrapper">
          
        <div className="left-side">
          <div className="graph-column">
            {shouldDisplayGraph(revenueData) && (
              <Plot
                data={[
                  {
                    x: revenueData.xAxisData,
                    y: revenueData.yAxisData,
                    type: "scatter",
                    mode: "lines",
                    marker: { color: "blue" }
                  }
                ]}
                layout={{
                  width: 600,
                  height: 400,
                  title: "Revenue",
                  xaxis: { title: "Date" },
                  yaxis: { title: "Revenue Values" }
                }}
                config={{ displayModeBar: false }}
              />
            )}

            {shouldDisplayGraph(netProfitData) && (
              <Plot
                data={[
                  {
                    x: netProfitData.xAxisData,
                    y: netProfitData.yAxisData,
                    type: "scatter",
                    mode: "lines",
                    marker: { color: "green" }
                  }
                ]}
                layout={{
                  width: 600,
                  height: 400,
                  title: "Net Profit",
                  xaxis: { title: "Date" },
                  yaxis: { title: "Net Profit Values" }
                }}
                config={{ displayModeBar: false }}
              />
            )}
          </div>
          </div>
          <div className="right-side">
          <div className="graph-column">
            {shouldDisplayGraph(netProfitMarginData) && (
              <Plot
                data={[
                  {
                    x: netProfitMarginData.xAxisData,
                    y: netProfitMarginData.yAxisData,
                    type: "scatter",
                    mode: "lines",
                    marker: { color: "orange" }
                  }
                ]}
                layout={{
                  width: 600,
                  height: 400,
                  title: "Net Profit Margin",
                  xaxis: { title: "Date" },
                  yaxis: { title: "Net Profit Margin Values" }
                }}
                config={{ displayModeBar: false }}
              />
            )}

            {shouldDisplayGraph(operatingExpensesData) && (
              <Plot
                data={[
                  {
                    x: operatingExpensesData.xAxisData,
                    y: operatingExpensesData.yAxisData,
                    type: "scatter",
                    mode: "lines",
                    marker: { color: "purple" }
                  }
                ]}
                layout={{
                  width: 600,
                  height: 400,
                  title: "Operating Expenses",
                  xaxis: { title: "Date" },
                  yaxis: { title: "Operating Expenses Values" }
                }}
                config={{ displayModeBar: false }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundamentalsPage;