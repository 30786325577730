import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import ServiceImg from "../assets/Background1.png";
import Screener from "../pages/ScreenerPageContent";

function Screenerpage() {
  return (
    <>
      <Navbar /> <Hero
        cName="hero"
        heroImg={ServiceImg}
        title="Screener"
        text="Screen the stock market with ease"
        url="/"
        btnClass="hide"
        buttonText="Portfolio"
      />
      <Screener />
      <Footer />
    </>
  );
}
export default Screenerpage;

