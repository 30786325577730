import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HomeImg from "../assets/Background2.png";
import HomeContent from "../components/HomeContent";
import Trip from "../components/Trip";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={HomeImg}
        title="finvision"
        text="Your Trusted Trading Assistant"
        url="/"
        btnClass="hide"
        buttonText="Portfolio"
      />
      <div style={{ margin: "0 60px" }}>
        <HomeContent />
      </div>
      {/* <Trip /> */}
      <Footer />
    </>
  );
}
export default Home;
