import React, { useState, useEffect } from "react";
// import {
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword
// } from "firebase/auth";
// import { signOut, getAuth } from "firebase/auth";

// import { database } from "../Supabase/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Import your context

const SignUp = () => {
  const { data } = useAuth();
  const [login, setLogin] = useState(true);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const { setData, user } = useAuth(); // Access the context function to set data and user object
  console.log(database);

  const history = useNavigate();

  const handleSubmit = (e, type) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (type === "signup") {
      createUserWithEmailAndPassword(database, email, password)
        .then((data) => {
          setSignUpSuccess(true); // Set the success state to true
          setLogin(true);
          setData(data);
        })
        .catch((err) => {
          alert(err.code);
          setSignUpSuccess(false);
        });
    } else {
      signInWithEmailAndPassword(database, email, password)
        .then((data) => {
          setLogin(true);
          setData(data);
          history("/ledger");
        })
        .catch((err) => {
          alert(err.code);
        });
    }
  };

  const handleSignOut = () => {
    const auth = getAuth(); // Get the Firebase authentication instance
    signOut(auth)
      .then(() => {
        setData(null);
        setSignUpSuccess(false);
      })
      .catch((error) => {
        console.error("Sign out error:", error);
      });
  };

  console.log(data, "data");

  return (
    <div className="from-container">
      {data ? (
        <div>
          Signed In As {data.user.providerData[0].uid} <br />
          <button onClick={handleSignOut}>Sign Out</button>
        </div>
      ) : (
        <div>
          <div className="row">
            <div
              className={!login ? "activeColor" : "pointer"}
              onClick={() => {
                setLogin(false);
                setSignUpSuccess(false); // Reset the success state
              }}
            >
              Sign Up
            </div>
            <div
              className={login ? "activeColor" : "pointer"}
              onClick={() => setLogin(true)}
            >
              Sign In
            </div>
          </div>

          <h1>{login ? "Sign In" : "Sign Up"}</h1>
          {signUpSuccess && (
            <div className="sent_message">Sign up successful!</div>
          )}
          <form onSubmit={(e) => handleSubmit(e, login ? "signin" : "signup")}>
            <input name="email" placeholder="Email" />
            <input name="password" type="password" placeholder="Password" />
            <button>{login ? "Sign In" : "Sign Up"}</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default SignUp;
