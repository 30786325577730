import CELH_Bullflag from "../assets/CELH_Bullflag.png";
import ETN_Bullflag from "../assets/ETN_Bullflag.png";
import INMD_RiskMangement from "../assets/INMD_RiskMangement.png";
import TTT_RiskManagement from "../assets/TTT_RiskManagement.png";
import HomeContentData from "./HomeContentData";
import "./HomeContentStyles.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Destination = () => {
  return (
    <div className="desitination">
      <h1 style={{ marginTop: "20px" }}>
        Unlock Your Potential With finvision
      </h1>

      <HomeContentData
        className="first-des"
        heading="Scan the Market for tickers that are positioning in buy set-ups"
        text="FinAIviser can scan the market for bullish price patterns, and give suggestions on how to enter a position"
        img1={CELH_Bullflag}
        img2={ETN_Bullflag}
      />
      <HomeContentData
        className="first-des-reverse"
        heading="Tightly Manage Risk"
        text="FinAIviser can show suggested sell points, along with estimates of a positions max loss"
        img1={INMD_RiskMangement}
        img2={TTT_RiskManagement}
      />
      <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
        finvision is built around the principals of CANSLIM, a researched and
        proven trading method
      </h2>
      <div className="flex-container">
        <div className="half-width-container">
          <Accordion className="custom-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>What is CANSLIM</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                CANSLIM is a trading style that focuses on a combination of
                technical and fundamental analysis, targeting stocks with strong
                earnings, momentum, and leadership
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="custom-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>How can FinAIvise help me?</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Wether you are experienced or a beginner, full or part time, one
                the most time consuming parts of trading is screening<br></br>
                FinAIvise can streamline this process by swiftly filtering
                through vast market data, saving time and effort in identifying
                tailored investment opportunities based on the CANSLIM approach
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="half-width-container">
          <Accordion className="custom-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>What is finvision's Screener</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                One of the main componenets of CANSLIM trading is managing risk
                around a point of entry<br></br>
                The screener uses rate of change pattern matching to screen the
                stock market for various bullish set ups, like the bull flag,
                and give assistance with underwriting risk management
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="custom-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>What is finvision's Ledger</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                The ledger feature displays the trading movements of finvision's
                in-house trading algorithm <br></br>
                It may be the case that a certain stock entered into a bullish
                entry point and you missed it. The ledger allows you to see
                which stocks may be in the midst of bull-runs and which stocks
                fell out of favour and were sold off<br></br>
                The ledger is not meant to be followed blindly, but can be used
                as a guide, and it's history can be viewed to see what type of
                sets ups worked, and which failed
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Destination;
