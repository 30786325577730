// TradingViewWidget.jsx
import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

export default function TradingViewWidget({ symbol }) {
  const onLoadScriptRef = useRef();
  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("tradingview_7856c") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          autosize: true,
          symbol: symbol,
          interval: "D",
          timezone: "Etc/UTC",
          theme: "light",
          style: "1",
          locale: "en",
          enable_publishing: false,
          container_id: "tradingview_7856c",
          studies: ["STD;EMA", "STD;RSI"]
        });
      }
    }
  }, [symbol]);

  const captureChartImage = () => {
    if ("takeScreenshot" in window.TradingView) {
      // Use the takeScreenshot method to capture the chart as an image
      window.TradingView.takeScreenshot(function (dataURL) {
        // dataURL contains the captured image in base64 format
        // You can save or display the image as needed
        console.log("Captured chart image:", dataURL);
      });
    }
  };

  return (
    <div className="tradingview-widget-container">
      <div
        id="tradingview_7856c"
        style={{ height: "500px", maxHeight: "100%" }} // Adjust the height here
      />
      <div className="tradingview-widget-copyright"></div>
    </div>
  );
}
