import { Component } from "react";
import "./HomeContentStyles.css";
import logoImg from "../assets/Logo.png";

class DestinationData extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className="des-text">
          <h2> {this.props.heading}</h2>
          <p> {this.props.text}</p>
        </div>
        <div className="image">
          <div className="image-container">
            <img alt="img" src={this.props.img1} />
          </div>
          <div className="image-container">
            <img alt="img" src={this.props.img2} />
          </div>
        </div>
      </div>
    );
  }
}

export default DestinationData;
