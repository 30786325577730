import "./HeroStyles.css";
import { useAuth } from "./AuthContext";

function Hero(props) {
  const { data } = useAuth();
  return (
    <>
      <div className={props.cName}>
        <img alt="HeroImg" src={props.heroImg} />
        <div className="hero-text">
          <h1>{props.title}</h1>
          <p> {props.text}</p>
          {data ? <p>{data.user.providerData[0].uid}</p> : <p></p>}
          <a href={props.url} className={props.btnClass}>
            {props.buttonText}
          </a>
          {props.children}
        </div>
      </div>
    </>
  );
}
export default Hero;
