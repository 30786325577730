import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./DetailsPageStyles.css";
import Plot from "react-plotly.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { useAuth } from "../components/AuthContext";
import supabase from "../Supabase/supabase";


function HoldingsPage() {
  const { data: user } = useAuth();
  const userName = user ? user.user.providerData[0].uid : null

  const today = new Date();
  const twoDaysAgo = new Date(today);
  twoDaysAgo.setDate(today.getDate() - 30);
  const [startDate, setStartDate] = useState(twoDaysAgo);
  const [endDate, setEndDate] = useState(new Date());

  const [tradeData, setTradeData] = useState([]);
  const [holdingsData, setHoldingsData] = useState([]); 
  const [profitData, setProfitData] = useState([]);
  const [watchlistData, setwatchlistData] = useState([]);
  const [dailyReturnsData, setDailyReturnsData] = useState([]);

  const [totalReturnsData, setTotalReturnsData] = useState(
    Array.from({ length: 5 }, (_, index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return {
        asofdate: date.toISOString().split("T")[0],
        cumulative_return: "0.00%", // or any default value you prefer
      };
    })
  );

  const [dateRange, setDateRange] = useState("1Y");  
  const [activeButton, setActiveButton] = useState("1Y");

  
  const [filterAll, setFilterAll] = useState(false);
  const [filterBuy, setFilterBuy] = useState(true);
  const [filterSell, setFilterSell] = useState(true);

  async function fetchTableData(startDate, endDate) {
    try {
      const { data, error } = await supabase
        .from("user_profit_summary")
        .select("profit_ratio, avg_percent_return_positive, avg_percent_return_negative, weighted_average_return")
        .eq("user", userName);
      console.log("watchlist:", data);
      setProfitData(data);
    } catch (error) {
      // Handle any errors that may occur during the query
      console.error("Error fetching profit_summary:", error.message);
      return; // Add this to stop further execution
    }


    try {
      const { data, error } = await supabase
        .from("watchlist")
        .select("symbol")
        .eq("user", userName);
      console.log("watchlist:", data);
      setwatchlistData(data);
    } catch (error) {
      // Handle any errors that may occur during the query
      console.error("Error fetching watchlist:", error.message);
      return; // Add this to stop further execution
    }

    try {
      const { data, error } = await supabase
        .from("trades")
        .select("symbol, asofdate, type, price, shares, totalvalue, notes")
        .gte("asofdate", startDate)
        .lte("asofdate", endDate)
        .eq("user", userName);
      console.log("data:", data);
      const filteredData = data.filter((row) => {
        return (
          (filterBuy && row.type === "Buy") ||
          (filterSell && row.type === "Sell")
        );
      });
      setTradeData(filteredData);
    } catch (error) {
      // Handle any errors that may occur during the query
      console.error("Error fetching trades:", error.message);
      return; // Add this to stop further execution
    }

    try {
      const { data: maxDateResult, error: maxDateError } = await supabase
      .from("daily_user_data")
      .select("asofdate")
      .eq("user", userName)
      .order("asofdate", { ascending: false })
      .limit(1);
    if (maxDateError) {
      console.error("Error fetching max date:", maxDateError);
    } 
      const maxDate = maxDateResult[0].asofdate;  
      const { data, error } = await supabase
        .from("daily_user_data")
        .select("user, symbol, asofdate, shares, marketvalue, cost_base, open_pl")
        .eq("user", userName)
        .eq("asofdate", maxDate);    
      if (error) {
        console.error("Error fetching data:", error);
      } 
      console.log("data HOLDINGS:", data);
      setHoldingsData(data);
    }catch (error) {
      // Handle any errors that may occur during the query
      console.error("Error fetching holdings:", error.message);
      return; // Add this to stop further execution
    }

    try {
      const { data, error} = await supabase
      .from("total_daily_returns")
      .select("asofdate, total_daily_return")
      .eq("user", userName)
      .gte("asofdate", startDate)
      .lte("asofdate", endDate)
      if (error) {
        console.error("Error fetching data:", error);
      } 
      console.log("data total daily reutrns:", data);
      setDailyReturnsData(calculateCumulativeReturns(data));      

    }catch (error) {
      // Handle any errors that may occur during the query
      console.error("Error fetching daily total returns:", error.message);
      return; // Add this to stop further execution
    }
    }  

    useEffect(() => {
      console.log("DailyReturnsDatas:", dailyReturnsData[length]);
    }, [dailyReturnsData]);

    const calculateCumulativeReturns = (data) => {
      let cumulativeReturn = 1; // Initial value
      return data.map((item) => {
        cumulativeReturn *= 1 + parseFloat(item.total_daily_return);
        return {
          asofdate: item.asofdate,
          cumulative_return: formatNumberToPercentage(cumulativeReturn - 1)
        };
      });
    };

    const getWeekAgoDate = () => {
      const today = new Date();
      const weekAgo = new Date(today);
      weekAgo.setDate(today.getDate() - 7);
      return weekAgo.toISOString().split("T")[0];
    };
  
    const getMonthAgoDate = () => {
      const today = new Date();
      const monthAgo = new Date(today);
      monthAgo.setMonth(today.getMonth() - 1);
      return monthAgo.toISOString().split("T")[0];
    };
  
    const getYearAgoDate = () => {
      const today = new Date();
      const yearAgo = new Date(today);
      yearAgo.setFullYear(today.getFullYear() - 1);
      return yearAgo.toISOString().split("T")[0];
    };
    
  useEffect(() => {
    const fetchTotalReturnsData = async () => {
      let fetchedData = null;
      let fromDate = null;
      let toDate = null;
      if (dateRange === "1W") {
        fromDate = getWeekAgoDate();
        toDate = new Date().toISOString().split("T")[0];
      } else if (dateRange === "1M") {
        fromDate = getMonthAgoDate();
        toDate = new Date().toISOString().split("T")[0];
      } else if (dateRange === "1Y") {
        fromDate = getYearAgoDate();
        toDate = new Date().toISOString().split("T")[0];
      } else if (dateRange === "Selected Range") {
        fromDate = startDate.toISOString().split("T")[0];
        toDate = endDate.toISOString().split("T")[0];
      } 

      if (fromDate !== null) {
        const { data, error } = await supabase
          .from("total_daily_returns")
          .select("asofdate, total_daily_return")
          .order("asofdate", { ascending: true })
          .eq("user",userName) // Filter by symbol if needed
          .gte("asofdate", fromDate)
          .lte("asofdate", toDate);
        if (error) {
          console.error("Error fetching total Data:", error.message);
          return;
        }
        fetchedData = data;
      } 
      console.log("fetchedData", fetchedData);
      const cumulativeReturns = calculateCumulativeReturns(fetchedData);
      if (userName) {
        // Set totalReturnsData only if a user is logged in
        setTotalReturnsData(cumulativeReturns);
      }
    };
    fetchTotalReturnsData();
  }, [dateRange, startDate, endDate]);

  useEffect(() => {
    console.log("totalReturnsData", totalReturnsData);
  }, [totalReturnsData]);

  useEffect(() => {
    // Fetch and load data when the component is initially mounted with default dates
    fetchTableData(
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );
  }, []);

  const formatNumberToPercentage = (number) => {
    return (parseFloat(number) * 100).toFixed(2) + "%";
  };

  const handleDateSubmit = async () => {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    if (startDate && endDate) {
      await fetchTableData(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0]
      );
    }
  };

  const inputFields = [
    {
      label: "Buy",
      value: filterBuy,
      setValue: setFilterBuy,
      className: "checkbox-field"
    },
    {
      label: "Sell",
      value: filterSell,
      setValue: setFilterSell,
      className: "checkbox-field"
    }
  ];
  return (
    <div>
      <div className="content-wrapper">
        <div className="left-side">
        <h1> Watchlist </h1>
        <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow className="table-row">
                  <TableCell className="table-header-cell">Symbol</TableCell>
                  <TableCell className="table-header-cell"></TableCell>
                  <TableCell className="table-header-cell"></TableCell>
                  <TableCell className="table-header-cell"></TableCell>
                  <TableCell className="table-header-cell">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {watchlistData && watchlistData.length > 0 ? (
  watchlistData.map((row, index) => (
<TableRow key={index} className="table-row">
  <TableCell className="table-cell">
    {row.symbol}
  </TableCell>
  <TableCell className="table-cell"></TableCell>
  <TableCell className="table-cell"></TableCell>
  <TableCell className="table-cell"></TableCell>
  <TableCell className="button-cell">
    <Link to={`/${row.symbol}`}>
      <button className="details-button">Details</button>
    </Link>
  </TableCell>
</TableRow>
  )) 
  ) : (
  <TableRow>
    <TableCell colSpan={2}> {/* Change this to colSpan={2} */}
      {userName ? (
        <span>No trade data available for {userName}</span>
      ) : (
        <span>Log in to track a Watch List</span>
      )}
    </TableCell>
  </TableRow>
)}
              </TableBody>
            </Table>
          </TableContainer>
        <h1> Holdings </h1>
        <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow className="table-row">
                  <TableCell className="table-header-cell">Symbol</TableCell>
                  <TableCell className="table-header-cell">Date</TableCell>
                  <TableCell className="table-header-cell">Market Value</TableCell>
                  <TableCell className="table-header-cell">Cost Base</TableCell>
                  <TableCell className="table-header-cell">Open P/L</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holdingsData && holdingsData.length > 0 ? (
                  holdingsData.map((row, index) => (
                    <TableRow key={index} className="table-row">
                      <TableCell className="table-cell">
                        {row.symbol}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.asofdate}
                      </TableCell>
                      <TableCell className="table-cell">
                        ${row.marketvalue.toFixed(2)}
                      </TableCell>
                      <TableCell className="table-cell">
                        ${row.cost_base.toFixed(2)}
                      </TableCell>
                      <TableCell className="table-cell">
                        ${row.open_pl.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
  <TableCell colSpan={3}>
  {userName ? (
  <span>No trade data available for {userName}</span>
) : (
  <span>Log in to track Holdings</span>
)}
  </TableCell>
</TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <h1> Trades </h1>
          <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow className="table-row">
                  <TableCell className="table-header-cell">Symbol</TableCell>
                  <TableCell className="table-header-cell">Date</TableCell>
                  <TableCell className="table-header-cell">Type</TableCell>
                  <TableCell className="table-header-cell">Price</TableCell>
                  <TableCell className="table-header-cell">Shares</TableCell>
                  <TableCell className="table-header-cell">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tradeData && tradeData.length > 0 ? (
                  tradeData.map((row, index) => (
                    <TableRow key={index} className="table-row">
                      <TableCell className="table-cell">
                        {row.symbol}
                      </TableCell>
                      <TableCell className="table-cell">
                        {new Date(row.asofdate).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="table-cell">{row.type}</TableCell>
                      <TableCell className="table-cell">
                        ${row.price}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.shares}
                      </TableCell>
                      <TableCell className="table-cell">
                        ${row.totalvalue}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
  <TableCell colSpan={3}>
  {userName ? (
  <span>No trade data available for {userName}</span>
) : (
  <span>Log in to track Trades</span>
)}
  </TableCell>
</TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="right-side">


        <h1> Win Rate </h1>
        <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow className="table-row">
                  <TableCell className="table-header-cell">Profit Ratio</TableCell>
                  <TableCell className="table-header-cell">Average Profit</TableCell>
                  <TableCell className="table-header-cell">Average Loss</TableCell>
                  <TableCell className="table-header-cell">Average Return</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {profitData && profitData.length > 0 ? (
  profitData.map((row, index) => (
<TableRow key={index} className="table-row">
  <TableCell className="table-cell">{formatNumberToPercentage(row.profit_ratio)}</TableCell> 
  <TableCell className="table-cell"> {formatNumberToPercentage(row.avg_percent_return_positive)}</TableCell>
  <TableCell className="table-cell"> {formatNumberToPercentage(row. avg_percent_return_negative)}</TableCell>
  <TableCell className="table-cell"> {formatNumberToPercentage(row.weighted_average_return)}</TableCell>
</TableRow>
  )) 
  ) : (
  <TableRow>
    <TableCell colSpan={2}> {/* Change this to colSpan={2} */}
      {userName ? (
        <span>No profit data available for {userName}</span>
      ) : (
        <span>Log in to track Win Rates</span>
      )}
    </TableCell>
  </TableRow>
)}
              </TableBody>
            </Table>
          </TableContainer>




          <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow className="table-row">
                  <TableCell className="table-header-cell">
                    Input Data
                  </TableCell>
                  <TableCell className="table-header-cell"></TableCell>
                  <TableCell className="table-header-cell"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="Select Date">
                  <TableCell>
                    <h3>Select Start Date</h3>
                    <DatePicker
                      selected={startDate}
                      onChange={(start_date) => setStartDate(start_date)}
                      dateFormat="yyyy-MM-dd"
                      className="custom-datepicker" // Apply custom CSS class
                    />
                  </TableCell>
                  <TableCell>
                    <h3>Select End Date</h3>
                    <DatePicker
                      selected={endDate}
                      onChange={(end_date) => setEndDate(end_date)}
                      dateFormat="yyyy-MM-dd"
                      className="custom-datepicker" // Apply custom CSS class
                    />
                  </TableCell>
                  <TableCell>
                    <div className="checkboxes-container">
                      {inputFields.map((field) =>
                        field.className === "checkbox-field" ? (
                          <div key={field.label} className="checkbox-item">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) =>
                                    field.setValue(e.target.checked)
                                  }
                                  name={field.label}
                                  color="primary"
                                />
                              }
                              label={field.label}
                            />
                          </div>
                        ) : null
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={handleDateSubmit}
                      className="submit-button"
                    >
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <TableContainer component={Paper} className="table-container">
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell className="table-header-cell">
                      Period's Return
                    </TableCell>
                    <TableCell>
                    {dailyReturnsData.length > 0 ? dailyReturnsData[dailyReturnsData.length - 1].cumulative_return  : '0.00%'}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </div>
          <div className="centered-buttons">
            <div className="input-fields-row">
              <button
                className={
                  activeButton === "1W" ? "active-button" : "inactive-button"
                }
                onClick={() => {
                  setDateRange("1W");
                  setActiveButton("1W");
                }}
              >
                1W
              </button>
              <button
                className={
                  activeButton === "1M" ? "active-button" : "inactive-button"
                }
                onClick={() => {
                  setDateRange("1M");
                  setActiveButton("1M");
                }}
              >
                1M
              </button>
              <button
                className={
                  activeButton === "1Y" ? "active-button" : "inactive-button"
                }
                onClick={() => {
                  setDateRange("1Y");
                  setActiveButton("1Y");
                }}
              >
                1Y
              </button>
              <button
                className={
                  activeButton === "Selected Range"
                    ? "active-button"
                    : "inactive-button"
                }
                onClick={() => {
                  setDateRange("Selected Range");
                  setActiveButton("Selected Range");
                }}
              >
                Selected Range
              </button>
            </div>
          </div>
          <div>
             {totalReturnsData.length > 0 && ( 
              <Plot
  key={totalReturnsData.length}
  data={[
    {
      // The Data was being displayed hightest date on the left before it was reversed
      x: totalReturnsData.map((item) => item.asofdate).reverse(), 
      y: totalReturnsData.map((item) => item.cumulative_return).reverse(),
      type: "scatter",
      mode: "lines+markers",
      marker: { color: "blue" },
      line: { shape: "linear" },
      name: "Total Returns",
    },
  ]}
  layout={{
    title: "Total Returns Over Time",
    xaxis: { title: "Date" },
    yaxis: { title: "Total Return" },
    autosize: true,
  }}
  style={{ width: "100%", height: "400px" }}
  config={{ displayModeBar: false }}
/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoldingsPage;
