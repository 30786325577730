import React from "react";
import { useParams } from "react-router-dom";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HomeImg from "../assets/Background2.png";
import DetailsPageContent from "../pages/DetailsPageContent";
import Footer from "../components/Footer";

function DetailsPage() {
  const { symbol } = useParams();
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-low"
        heroImg={HomeImg}
        title= {`Details for ${symbol}`} 
        text=""
        btnClass="hide"
        buttonText="Portfolio"
      />
      <DetailsPageContent/>
      <Footer />
    </>
  );
}

export default DetailsPage;
