import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ServiceImg from "../assets/Background1.png";
import Footer from "../components/Footer";
import MacroPageContent from "../pages/MacroPageContent";
function Ledger() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={ServiceImg}
        title="Macro Economic"
        text="Get a top down picture of the Market"
        url="/strategy"
        btnClass="hide"
        buttonText="Travel Plan"
      />
      <MacroPageContent />

      <Footer />
    </>
  );
}
export default Ledger;
