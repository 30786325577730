import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import supabase from "../Supabase/supabase";

const MacroPageContent = () => {
  const [sectorData, setSectorData] = useState([]);

  useEffect(() => {
    const fetchPercentageNewHighData = async () => {
      try {
        const { data, error } = await supabase
          .from("percentage_new_high")
          .select("asofdate, sector, percentagenewhigh_twelve_week");

        if (error) {
          console.error("Error fetching percentage_new_high data:", error.message);
          return;
        }

        setSectorData(data);
      } catch (error) {
        console.error("Error fetching percentage_new_high data:", error);
      }
    };

    fetchPercentageNewHighData();
  }, []);

  // Group data by sector
  const groupedData = sectorData.reduce((acc, entry) => {
    if (!acc[entry.sector]) {
      acc[entry.sector] = [];
    }
    acc[entry.sector].push(entry);
    return acc;
  }, {});

  return (
    <>
      <h2>Macro Economic Indicators</h2>
      <div>
        {/* Other Plots */}
      </div>
      <div>
        <Plot
          data={Object.keys(groupedData).map((sector) => ({
            x: groupedData[sector].map((entry) => entry.asofdate),
            y: groupedData[sector].map((entry) => entry.percentagenewhigh_twelve_week),
            mode: "lines",
            name: sector
          }))}
          layout={{
            title: "Percentage New High (Twelve Week) by Sector",
            xaxis: { title: "Date" },
            yaxis: { title: "Percentage New High (Twelve Week)" }
          }}
          config={{ displayModeBar: false }}
        />
      </div>
    </>
  );
};

export default MacroPageContent;
