import "./ContactStyles.css";
import emailjs from "emailjs-com";
import React, { useState } from "react";

const Mailer = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_lophjdo",
        "MagicLinkTemplate",
        e.target,
        "mEwbe3Ua0Z01MlEN6"
      )
      .then((res) => {
        console.log(res);
        setEmailSent(true);
        setEmailError(false);
      })
      .catch((err) => console.log(err));
    setEmailSent(false);
    setTimeout(() => {
      setEmailError(true);
    }, 3000);
  }
  return (
    <div className="from-container">
      <h1>Send Us a Message!</h1>
      {emailError && !emailSent && (
        <p className="error_message">
          Failed to send email. Please try again later.
        </p>
      )}

      {emailSent && <p className="sent_message">Email sent successfully!</p>}

      <form onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Name" />
        <input type="email" name="user_email" placeholder="Email" />
        <textarea name="message" placeholder="Message" rows="4"></textarea>
        <button type="submit" value="send">
          Send Message
        </button>
      </form>
    </div>
  );
};

export default Mailer;
